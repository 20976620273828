export default class Album {
  constructor(
    name,
    description,
    typeId = 1,
    capacity,
    isPublished = false,
    albumMedias = []
  ) {
    this.name = name;
    this.system_type_id = typeId;
    this.capacity = capacity;
    this.description = description;
    this.is_published = isPublished;
    this.album_medias = albumMedias;
  }
}
